import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Home = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  async function handleRegistration(e) {
    e.preventDefault();
    await axios({
      url: 'https://api.hsforms.com/submissions/v3/integration/submit/7841737/b5aeb605-affa-4dbc-a62f-0696b4bcf9fb',
      method: 'post',
      data: {
        fields: [
          {
            name: 'email',
            value: email,
          },
        ],
        context: {
          pageUri: 'https://penalty.app',
          pageName: 'Waiting list',
        },
      },
    });
    setSubmitted(true);
  }

  return (
  <>
    {/* <div className="loader">
      <div className="loading-animation"></div>
    </div> */}
    <section className="min-vh-100 bg-primary-3 text-light py-5 o-hidden">
      <div className="container position-relative layer-2">
        <div className="row justify-content-center mb-md-6">
          <div className="col-auto">
            <Link to="/" style={{fontSize: '30px'}}>
                PenaltyApp
            </Link>
          </div>
        </div>
        <div className="row justify-content-center pt-6">
          <div className="col-xl-9 col-lg-10" data-detailed data-countdown-date="2020/09/03">
            <div data-active className="row text-center">
              <div className="col">
                <div data-aos="fade-up" data-aos-delay="100">
                  <div className="display-4 mb-2" data-weeks data-format="%w"></div>
                  <span className="h6 mb-0" data-weeks-label></span>
                </div>
              </div>
              <div className="col">
                <div data-aos="fade-up" data-aos-delay="200">
                  <div className="display-4 mb-2" data-days data-format="%d"></div>
                  <span className="h6 mb-0" data-days-label></span>
                </div>
              </div>
              <div className="col">
                <div data-aos="fade-up" data-aos-delay="300">
                  <div className="display-4 mb-2" data-hours></div>
                  <span className="h6 mb-0" data-hours-label></span>
                </div>
              </div>
              <div className="col">
                <div data-aos="fade-up" data-aos-delay="400">
                  <div className="display-4 mb-2" data-minutes></div>
                  <span className="h6 mb-0" data-minutes-label></span>
                </div>
              </div>
              <div className="col">
                <div data-aos="fade-up" data-aos-delay="500">
                  <div className="display-4 mb-2" data-seconds></div>
                  <span className="h6 mb-0" data-seconds-label></span>
                </div>
              </div>
            </div>
            <div data-elapsed style={{display: "none"}}>
              <h4>This is the fallback for when the countdown is elapsed</h4>
            </div>
          </div>
        </div>
        <div className="row justify-content-center py-5">
          <div className="col text-center">
            <h1>Une nouvelle expérience pour supporter vos équipes préférées arrive...</h1>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8 col-md-10 text-center">

            <form noValidate onSubmit={handleRegistration}>
              <div className="d-md-flex mb-3 justify-content-center">
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  className="mx-1 mb-2 mb-md-0 form-control form-control-lg"
                  name="email"
                  placeholder="Adresse E-mail"
                  required />
                <button type="submit" className="mx-1 btn btn-primary btn-loading btn-lg" data-loading-text="Sending">
                  <img className="icon" src="/assets/img/icons/theme/code/loading.svg" alt="loading icon" data-inject-svg />
                  <span>Prévenez-moi !</span>
                </button>
              </div>
              <div>
                <div className={`alert alert-success ${!submitted ? "d-none" : ""}`} role="alert" data-success-message>
                  Merci nous allons entrer en contact avec vous rapidement.
                </div>
                <div className="d-none alert alert-danger" role="alert" data-error-message>
                  Merci de compléter tous les champs.
                </div>
              </div>
            </form>
            <div className="text-small text-muted">
              Votre e-mail ne sera pas partagé.
              <br className="visible-md" />Consulter notre <Link to="/privacy-policy">Privacy Policy</Link> pour plus d'informations.
            </div>

          </div>
        </div>
      </div>
      <div className="decoration-wrapper d-none d-sm-block">
        <div className="decoration right middle-y scale-5" data-jarallax-element="200">
          <img className="bg-primary-2" src="/assets/img/decorations/deco-blob-3.svg" alt="deco-blob-3 decoration" data-inject-svg />
        </div>
        <div className="decoration top left scale-5" data-jarallax-element="50">
          <img className="bg-primary" src="/assets/img/decorations/deco-blob-13.svg" alt="deco-blob-13 decoration" data-inject-svg />
        </div>
      </div>
    </section>
    <script id="CookieDeclaration" src="https://consent.cookiebot.com/1ff77c76-6dd8-46d2-b940-5697afaa1df6/cd.js" type="text/javascript" async></script>
    </>
  );
};

export default Home;
