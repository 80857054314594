import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <>
      <section className="min-vh-100 bg-primary-3 text-light py-5 o-hidden">
        <div className="container position-relative layer-2">
          <div className="row justify-content-center mb-md-6">
            <div className="col-auto">
              <Link to="/" style={{fontSize: '30px'}}>
                PenaltyApp
              </Link>
            </div>
          </div>
          <div className="row justify-content-center">
            <h1>Politique de confidentialité</h1>
            <div className="col-md-12">
              <p>Le respect de votre vie privée est codé dans notre ADN. 
              Depuis que nous avons lancé PenaltyApp, nous avons aspiré à construire nos services avec un ensemble de principes de confidentialité solides à l'esprit.</p>
              <p>Si vous vivez dans un pays de l'Espace économique européen (qui comprend l'Union européenne) et dans tout autre pays ou territoire inclus (collectivement dénommé la région européenne),
              vos services sont fournis par Arnaud Baali, qui est également le responsable du traitement des données lorsque vous utilisez nos services.</p>
              <p>Si vous vivez dans un autre pays, à l'exception de ceux de la région européenne, vos services sont fournis par Arnaud Baali, qui est également votre responsable du traitement des données lorsque vous utilisez nos services.</p>
              <p>Cette politique de confidentialité s'applique à tous nos services, sauf indication contraire.</p>
              <h2>Informations que nous collectons</h2>
              <p>PenaltyApp doit recevoir ou collecter certaines informations pour exploiter, fournir, améliorer, comprendre, personnaliser, soutenir et commercialiser nos Services, 
              y compris lorsque vous installez, accédez ou utilisez nos Services. 
              Les types d'informations que nous recevons et collectons dépendent de la façon dont vous utilisez nos services.</p>
              <h3>Informations que vous fournissez</h3>
              <ul>
                <li><b>Vos informations de compte.</b> Vous fournissez votre numéro de téléphone mobile et des informations de base (y compris un nom de profil) pour créer un compte PenaltyApp. 
                Vous nous fournissez régulièrement, conformément aux lois applicables, les numéros de téléphone de votre carnet d'adresses mobile, y compris ceux des utilisateurs de nos Services et de vos autres contacts. 
                Vous pouvez nous fournir une adresse e-mail. Vous pouvez également ajouter d'autres informations à votre compte, comme une photo de profil et des informations.</li>
                <li><b>Vos connexions.</b> Pour vous aider à organiser la façon dont vous communiquez avec les autres, nous pouvons vous aider à identifier vos contacts qui utilisent également PenaltyApp, et vous pouvez créer, rejoindre ou être ajouté à des groupes et des listes de diffusion, et ces groupes et listes sont associés à vos informations de compte. Vous donnez un nom à vos groupes. 
                Vous pouvez choisir de fournir une photo ou une description de profil de groupe.</li>
                <li><b>Service client.</b> Vous pouvez nous fournir des informations relatives à votre utilisation de nos services, y compris des copies de vos messages, et comment vous contacter afin que nous puissions vous fournir un support client. Par exemple, vous pouvez nous envoyer un e-mail avec des informations relatives aux performances de notre application ou à d'autres problèmes.</li>
              </ul>
              <h3>Informations collectées automatiquement</h3>
              <ul>
                <li><b>Informations sur l'utilisation et le journal.</b> Nous collectons des informations sur votre activité sur nos Services, 
                telles que des informations relatives aux services, aux diagnostics et aux performances. 
                Cela comprend des informations sur votre activité (y compris la façon dont vous utilisez nos services, vos paramètres de services, 
                la façon dont vous interagissez avec les autres utilisateurs de nos services, ainsi que l'heure, la fréquence et la durée 
                de vos activités et interactions), les fichiers journaux et les diagnostics, les plantages, site Web et journaux et rapports de performances. 
                Cela comprend également des informations sur la date à laquelle vous vous êtes inscrit pour utiliser nos services, 
                les fonctionnalités que vous utilisez comme nos fonctionnalités de messagerie, d'appel, de statut ou de groupe, la photo de profil, 
                les informations, que vous soyez en ligne, la dernière fois que vous avez utilisé nos services (votre "dernier") vu ") 
                et la dernière fois que vous avez mis à jour vos informations sur.</li>
                <li><b>Informations sur le périphérique et la connexion.</b> Nous collectons des informations spécifiques à l'appareil et à la connexion lorsque vous installez, accédez ou utilisez nos Services. 
                Cela inclut des informations telles que le modèle matériel, les informations sur le système d'exploitation, le niveau de la batterie, la force du signal, la version de l'application, les informations sur le navigateur et le réseau mobile, les informations de connexion, y compris le numéro de téléphone, l'opérateur mobile ou le FAI, la langue et le fuseau horaire, et IP, les informations sur le fonctionnement de l'appareil, et des identifiants tels que les identifiants d'appareil.</li>
                <li><b>Information de Lieu.</b> Nous collectons des informations de localisation de l'appareil si vous utilisez nos fonctionnalités de localisation, 
                comme lorsque vous choisissez de partager votre position avec vos contacts, d'afficher les positions à proximité ou celles que d'autres ont partagées avec vous, 
                etc., et à des fins de diagnostic et de dépannage, comme si vous êtes avoir des problèmes avec les fonctionnalités de localisation de notre application. 
                Nous utilisons diverses technologies pour déterminer l'emplacement, y compris IP, GPS, signaux Bluetooth et des informations sur les points d'accès Wi-Fi, 
                les balises et les tours de téléphonie cellulaire à proximité.</li>
                <li><b>Cookies.</b> Nous utilisons des cookies pour exploiter et fournir nos Services, y compris pour fournir nos Services basés sur le Web, 
                améliorer vos expériences, comprendre comment nos Services sont utilisés et personnaliser nos Services. 
                Par exemple, nous utilisons des cookies pour fournir PenaltyApp pour le Web et le bureau et d'autres services Web. 
                Nous pouvons également utiliser des cookies pour comprendre lesquelles de nos FAQ sont les plus populaires et pour vous montrer du contenu pertinent lié à nos services. 
                De plus, nous pouvons utiliser des cookies pour mémoriser vos choix, comme vos préférences linguistiques, pour offrir une expérience plus sûre, 
                et sinon pour personnaliser nos services pour vous.</li>
              </ul>
              <h3>Informations tierces</h3>
              <ul>
                <li><b>Informations que d'autres fournissent sur vous.</b> Nous recevons des informations vous concernant d'autres utilisateurs et entreprises. 
                Par exemple, lorsque d'autres utilisateurs ou entreprises que vous connaissez utilisent nos Services, 
                ils peuvent fournir votre numéro de téléphone, votre nom et d'autres informations (comme des informations de leur carnet d'adresses mobile ou dans le cas des entreprises, des informations supplémentaires sur vous telles que des identifiants uniques),
                tout comme vous pouvez fournir le leur, ou ils peuvent vous envoyer un message, envoyer des messages à des groupes auxquels vous appartenez ou vous appeler. 
                Nous demandons à chacun de ces utilisateurs et entreprises d'avoir des droits légaux de collecter, d'utiliser et de partager vos informations avant 
                de nous fournir des informations.</li>
                <li><b>Fournisseurs de services tiers.</b> Nous travaillons avec des fournisseurs de services tiers pour nous aider à exploiter, fournir, améliorer, comprendre, 
                personnaliser, soutenir et commercialiser nos services. Par exemple, nous travaillons avec des entreprises pour distribuer nos applications, 
                fournir notre infrastructure, la livraison et d'autres systèmes, fournir l'emplacement, la carte et les informations sur les lieux, traiter les paiements, nous aider à comprendre comment les gens utilisent nos services, commercialiser nos services, vous aider à vous connecter avec les entreprises utilisant nos services, mener des enquêtes et des recherches pour nous, et aider avec le service client. Ces sociétés peuvent nous fournir des informations vous concernant dans certaines circonstances; par exemple, les magasins d'applications peuvent nous fournir des rapports pour nous aider à diagnostiquer et à résoudre les problèmes de service.</li>
                <li><b>Services tiers.</b> Nous vous permettons d'utiliser nos services en relation avec des services tiers.
                Si vous utilisez nos services avec de tels services tiers, nous pouvons recevoir des informations vous concernant de leur part; 
                par exemple, si vous utilisez le bouton de partage PenaltyApp sur un service d'actualités pour partager un article d'actualité avec vos contacts, 
                groupes ou listes de diffusion PenaltyApp sur nos services, ou si vous choisissez d'accéder à nos services via la promotion d'un opérateur de 
                téléphonie mobile ou d'un fournisseur d'appareils de Nos services. Veuillez noter que lorsque vous utilisez des services tiers, leurs propres conditions 
                et politiques de confidentialité régissent ces services.</li>
              </ul>
              <h2>Comment nous utilisons les informations</h2>
              <p>Nous utilisons les informations dont nous disposons (sous réserve des choix que vous faites) pour exploiter, fournir, améliorer, comprendre, personnaliser, 
              soutenir et commercialiser nos Services. Voici comment:</p>
              <ul>
                <li><b>Nos services.</b> Nous utilisons les informations dont nous disposons pour exploiter et fournir nos Services, y compris pour fournir un support client, 
                et améliorer, corriger et personnaliser nos Services. Nous comprenons comment les gens utilisent nos Services et analysent et utilisent les informations dont nous 
                disposons pour évaluer et améliorer nos Services, rechercher, développer et tester de nouveaux services et fonctionnalités, et mener des activités de dépannage. 
                Nous utilisons également vos informations pour vous répondre lorsque vous nous contactez.</li>
                <li><b>Sûreté et sécurité.</b> Nous vérifions les comptes et les activités, et promouvons la sûreté et la sécurité sur et hors de nos Services, par exemple en 
                enquêtant sur des activités suspectes ou des violations de nos Conditions, et pour nous assurer que nos Services sont utilisés légalement.</li>
                <li><b>Aucune bannière publicitaire tierce.</b> Nous n'autorisons toujours pas les bannières publicitaires tierces sur PenaltyApp. 
                Nous n'avons pas l'intention de les présenter, mais si jamais nous le faisons, nous mettrons à jour cette politique.</li>
              </ul>
              <h2>Informations que vous et nous partageons</h2>
              <p>Vous partagez vos informations lorsque vous utilisez et communiquez via nos Services, et nous partageons vos informations pour nous aider à exploiter, 
              fournir, améliorer, comprendre, personnaliser, soutenir et commercialiser nos Services.</p>
              <ul>
                <li><b>Envoyez vos informations à ceux avec qui vous choisissez de communiquer.</b> Vous partagez vos informations (y compris les messages) lorsque vous utilisez et 
                communiquez via nos services.</li>
                <li><b>Information sur le compte.</b> Votre numéro de téléphone, vos informations de profil, les informations, les dernières informations consultées 
                et les reçus peuvent être disponibles pour toute personne qui utilise nos services, bien que vous puissiez configurer vos paramètres de services pour 
                gérer certaines informations disponibles pour les autres utilisateurs et entreprises avec lesquels vous communiquez.</li>
                <li><b>Vos contacts et autres.</b> Les utilisateurs et les entreprises avec lesquels vous communiquez peuvent stocker ou partager vos informations 
                (y compris votre numéro de téléphone ou vos messages) avec d'autres sur et hors de nos Services. Vous pouvez utiliser vos paramètres de Services et la 
                fonctionnalité de blocage de nos Services pour gérer les utilisateurs de nos Services avec lesquels vous communiquez et certaines informations que vous partagez.</li>
                <li><b>Fournisseurs de services tiers.</b> Nous travaillons avec des fournisseurs de services tiers pour nous aider à exploiter, fournir, améliorer, comprendre, 
                personnaliser, soutenir et commercialiser nos services. Lorsque nous partageons des informations avec des fournisseurs de services tiers à ce titre, nous leur 
                demandons d'utiliser vos informations en notre nom conformément à nos instructions et conditions.</li>
                <li><b>Services tiers.</b> Lorsque vous utilisez des services tiers intégrés à nos services, ils peuvent recevoir des informations sur ce que vous partagez avec eux. 
                Par exemple, si vous utilisez un service de sauvegarde de données intégré à nos services (comme iCloud ou Google Drive), ils recevront des informations que 
                vous partagez avec eux. Si vous interagissez avec un service tiers lié via nos Services, vous pouvez fournir des informations directement à ce tiers. 
                Veuillez noter que lorsque vous utilisez des services tiers, leurs propres conditions et politiques de confidentialité régissent votre utilisation de ces services.</li>
              </ul>
              <h2>Cession, changement de contrôle et transfert</h2>
              <p>Tous nos droits et obligations en vertu de notre politique de confidentialité sont librement attribuables par nous à l'une de nos sociétés affiliées, 
              dans le cadre d'une fusion, d'une acquisition, d'une restructuration ou d'une vente d'actifs, ou par effet de la loi ou autrement, et nous pouvons 
              transférer vos informations à l'un de nos affiliés, entités successeurs ou nouveau propriétaire.</p>
              <h2>Comment le règlement général sur la protection des données s'applique aux utilisateurs de notre région européenne</h2>
              <p>Nos bases légales pour le traitement des informations</p>
              <p>Nous collectons, utilisons et partageons les informations dont nous disposons, comme décrit ci-dessus:</p>
              <ul>
                <li>si nécessaire pour remplir nos conditions;</li>
                <li>conformément à votre consentement, que vous pouvez révoquer à tout moment;</li>
                <li>si nécessaire pour se conformer à nos obligations légales;</li>
                <li>à l'occasion pour protéger vos intérêts vitaux ou ceux d'autrui;</li>
                <li>si nécessaire dans l'intérêt public; et</li>
                <li>si cela est nécessaire pour nos intérêts légitimes (ou ceux d'autrui), y compris nos intérêts à fournir un service innovant, pertinent, sûr et 
                rentable à nos utilisateurs et partenaires, à moins que ces intérêts ne soient outrepassés par vos intérêts ou droits et libertés 
                fondamentaux qui nécessitent la protection de données personnelles. Apprendre encore plus</li>
              </ul>
              <h3>Comment vous exercez vos droits</h3>
              <p>En vertu du règlement général sur la protection des données ou d'autres lois locales applicables, vous avez le droit d'accéder, de rectifier, de porter et 
              d'effacer vos informations, ainsi que le droit de restreindre et de vous opposer à certains traitements de vos informations. 
              Cela comprend le droit de s'opposer à notre traitement de vos informations pour le marketing direct et le droit de s'opposer à notre traitement 
              de vos informations lorsque nous effectuons une tâche dans l'intérêt public ou poursuivons nos intérêts légitimes ou ceux d'un tiers. 
              Vous pouvez accéder à vos informations ou les transférer à l'aide de notre fonction de demande d'informations sur le compte (disponible sous Paramètres> Compte). 
              Vous pouvez accéder à des outils pour rectifier, mettre à jour et effacer vos informations directement dans l'application, comme décrit dans 
              la section Gestion et suppression de vos informations. Si nous traitons vos informations sur la base de nos intérêts légitimes ou de ceux 
              d'un tiers, ou dans l'intérêt public, vous pouvez vous opposer à ce traitement, et nous cesserons de traiter vos informations, sauf si le traitement est 
              basé sur des motifs légitimes impérieux ou est nécessaire pour des raisons juridiques. Vous pouvez également vous opposer à notre traitement de vos 
              informations et en savoir plus sur vos options pour restreindre la façon dont nous utilisons vos informations en allant ici. 
              Lorsque nous utilisons vos informations pour le marketing direct pour nos propres services, vous pouvez toujours vous opposer et vous 
              désinscrire des futurs messages marketing en utilisant le lien de désabonnement dans de telles communications, ou en utilisant notre fonctionnalité 
              "Bloquer" dans l'application.</p>
              <h2>Gérer et supprimer vos informations</h2>
              <p>Nous stockons les informations jusqu'à ce qu'il ne soit plus nécessaire de fournir nos services, ou jusqu'à ce que votre compte soit supprimé, selon la première éventualité. Il s'agit d'une détermination au cas par cas qui dépend de facteurs tels que la nature des informations, les raisons pour lesquelles elles sont collectées et traitées et les besoins de conservation opérationnels ou juridiques pertinents.</p>
              <p>Si vous souhaitez gérer, modifier, limiter ou supprimer vos informations, nous vous permettons de le faire via les outils suivants:</p>
              <ul>
                <li><b>Paramètres des services.</b> Vous pouvez modifier vos paramètres de services pour gérer certaines informations disponibles pour les autres utilisateurs. Vous pouvez gérer vos contacts, groupes et listes de diffusion, ou utiliser notre fonction de blocage pour gérer les utilisateurs avec lesquels vous communiquez.</li>
                <li><b>Modification de votre numéro de téléphone mobile, du nom et de l'image de votre profil et des informations</b> sur vous Vous devez modifier votre numéro de téléphone mobile à l'aide de notre fonction de changement de numéro dans l'application et transférer votre compte vers votre nouveau numéro de téléphone mobile. Vous pouvez également modifier le nom de votre profil, votre photo de profil et les informations à tout moment.</li>
                <li><b>Suppression de votre compte PenaltyApp.</b> Vous pouvez supprimer votre compte PenaltyApp à tout moment (y compris si vous souhaitez révoquer votre consentement à notre utilisation de vos informations) en utilisant notre fonctionnalité de suppression de mon compte intégrée. Lorsque vous supprimez votre compte PenaltyApp, vos messages non livrés sont supprimés de nos serveurs ainsi que toutes vos autres informations dont nous n'avons plus besoin pour fonctionner et fournir nos services. Gardez à l'esprit que si vous supprimez uniquement nos services de votre appareil sans utiliser notre fonction de suppression de mon compte intégrée, vos informations peuvent être stockées avec nous pendant une période plus longue. N'oubliez pas que lorsque vous supprimez votre compte, cela n'affecte pas les informations que les autres utilisateurs détiennent à votre sujet, comme leur copie des messages que vous leur avez envoyés.</li>
              </ul>
              <h2>Loi et protection</h2>
              <p>Nous collectons, utilisons, conservons et partageons vos informations si nous croyons de bonne foi qu'il est raisonnablement nécessaire de: 
              (a) répondre conformément à la loi ou à la réglementation applicable, aux procédures judiciaires ou aux demandes du gouvernement; 
              (b) appliquer nos Conditions et toutes autres conditions et politiques applicables, y compris pour les enquêtes sur les violations potentielles; 
              (c) détecter, enquêter, prévenir et traiter les fraudes et autres activités illégales, problèmes de sécurité ou problèmes techniques; ou 
              (d) protéger les droits, la propriété et la sécurité de nos utilisateurs, PenaltyApp, les sociétés Facebook ou autres, notamment pour prévenir la mort ou des lésions corporelles imminentes.</p>
              <h2>Mises à jour de notre politique</h2>
              <p>Nous vous informerons avant d'apporter des modifications à cette politique de confidentialité et vous donnerons la possibilité d'examiner la politique de confidentialité révisée avant de choisir de continuer à utiliser nos services.</p>
              <h2>Contact Information</h2>
              <p>Elite Business ltd
              Attn: Privacy Policy
              71-75 Shelton Street, Covent Garden, London, England, WC2H 9JQ
              </p>              
            </div>
          </div>
        </div>
        <div className="decoration-wrapper d-none d-sm-block">
          <div className="decoration right middle-y scale-5" data-jarallax-element="200">
            <img className="bg-primary-2" src="/assets/img/decorations/deco-blob-3.svg" alt="deco-blob-3 decoration" data-inject-svg />
          </div>
          <div className="decoration top left scale-5" data-jarallax-element="50">
            <img className="bg-primary" src="/assets/img/decorations/deco-blob-13.svg" alt="deco-blob-13 decoration" data-inject-svg />
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
