import React from 'react';
import { Link } from 'react-router-dom';

const TermsOfUse = () => {
  return (<>
      <section className="min-vh-100 bg-primary-3 text-light py-5 o-hidden">
        <div className="container position-relative layer-2">
          <div className="row justify-content-center mb-md-6">
            <div className="col-auto">
              <Link to="/" style={{fontSize: '30px'}}>
                PenaltyApp
              </Link>
            </div>
          </div>
          <div className="row justify-content-center">
            <h1>Conditions d'utilisation</h1>
            <div className="col-md-12">
              <p>Afin de fournir nos services (tels que définis ci-dessous) par le biais de nos applications, services, fonctionnalités, 
              logiciels ou site Web, nous devons obtenir votre accord exprès à nos conditions d'utilisation (les «conditions»). 
              Vous acceptez nos conditions en vous inscrivant, en installant, en accédant ou en utilisant nos applications, 
              services, fonctionnalités, logiciels ou site Web.</p>
              <h3>Nos services</h3>
              <p>Si vous vivez dans un pays de l'Espace économique européen (qui comprend l'Union européenne) et dans tout autre pays 
              ou territoire inclus (collectivement dénommée la "Région européenne"), PenaltyApp Ireland Limited vous fournit 
              les services décrits ci-dessous qui vous fournissent les services décrits ci-dessous (collectivement, les «Services»):</p>
              <ul>
                <li>Principes de confidentialité et de sécurité. Depuis que nous avons lancé PenaltyApp, nous avons conçu nos services 
                en gardant à l'esprit de solides principes de confidentialité et de sécurité.</li>
                <li>Vous connecter avec d'autres personnes. Nous vous proposons des moyens de communiquer avec d'autres utilisateurs de 
                PenaltyApp, notamment par le biais de messages, d'appels vocaux et vidéo, d'envoyer des images et des vidéos, 
                d'afficher votre statut et de partager votre position avec d'autres lorsque vous le souhaitez. Nous pouvons fournir 
                une plateforme pratique qui vous permet d'envoyer et de recevoir de l'argent vers ou depuis d'autres utilisateurs 
                sur notre plateforme. PenaltyApp travaille avec des partenaires, des fournisseurs de services et des sociétés 
                affiliées pour nous aider à vous fournir des moyens de vous connecter à leurs services. Nous utilisons les informations 
                que nous recevons d'eux pour aider à exploiter, fournir et améliorer nos Services.</li>
                <li>Façons d'améliorer nos services. Nous analysons la façon dont vous utilisez PenaltyApp, afin d'améliorer tous les 
                aspects de nos services décrits ici, y compris en aidant les entreprises qui utilisent PenaltyApp à mesurer 
                l'efficacité et la distribution de leurs services et messages. PenaltyApp utilise les informations dont il dispose et 
                travaille également avec des partenaires, des fournisseurs de services et des sociétés affiliées pour ce faire.</li>
                <li>Communiquer avec les entreprises. Nous fournissons des moyens pour vous et des tiers, comme les entreprises, de 
                communiquer entre eux à l'aide de PenaltyApp, par exemple via des informations de commande, de transaction et de 
                rendez-vous, des notifications de livraison et d'expédition, des mises à jour de produits et services et de marketing. 
                Les messages que vous pouvez recevoir contenant du marketing peuvent inclure une offre pour quelque chose qui pourrait 
                vous intéresser. Nous ne voulons pas que vous ayez une expérience de spam; comme pour tous vos messages, vous pouvez 
                gérer ces communications et nous honorerons les choix que vous ferez.</li>
                <li>Sûreté et sécurité. Nous travaillons pour protéger la sûreté et la sécurité de PenaltyApp en traitant de manière 
                appropriée les personnes et les activités abusives et les violations de nos Conditions. Nous interdisons l'utilisation 
                abusive de nos services, les comportements préjudiciables envers autrui et les violations de nos conditions et politiques, 
                et nous nous attaquons aux situations dans lesquelles nous pourrions aider à soutenir ou protéger notre communauté. 
                Nous développons des systèmes automatisés pour améliorer notre capacité à détecter et à supprimer les personnes abusives 
                et les activités qui peuvent nuire à notre communauté et à la sécurité et la sûreté de nos services. Si nous apprenons 
                l'existence de personnes ou d'activités comme celle-ci, nous prendrons les mesures appropriées en supprimant ces 
                personnes ou activités ou en contactant les forces de l'ordre. Nous partageons des informations avec d'autres sociétés 
                affiliées lorsque nous apprenons une mauvaise utilisation ou une conduite nuisible de la part d'une personne utilisant 
                nos Services.</li>
                <li>Permettre un accès mondial à nos services. Pour exploiter notre service mondial, nous devons stocker et distribuer 
                du contenu et des informations dans des centres de données et des systèmes à travers le monde, y compris en dehors 
                de votre pays de résidence. Cette infrastructure peut être détenue ou exploitée par nos prestataires de services 
                ou sociétés affiliées.</li>
              </ul>
              <p>PAS D'ACCÈS AUX SERVICES D'URGENCE: Il existe des différences importantes entre nos Services et votre téléphone 
              mobile et un service de téléphonie fixe et SMS. Nos services ne donnent pas accès aux services d'urgence ou aux 
              prestataires de services d'urgence, y compris la police, les pompiers ou les hôpitaux, ou ne se connectent pas aux points 
              de réponse de la sécurité publique. Vous devez vous assurer que vous pouvez contacter vos prestataires de services d'urgence 
              concernés via un téléphone mobile, un téléphone fixe ou un autre service.</p>
              <h3>À propos de nos services</h3>
              <p>Enregistrement. Vous devez vous inscrire à nos Services en utilisant des informations précises, 
              fournir votre numéro de téléphone portable actuel et, si vous le changez, mettre à jour votre numéro de téléphone 
              portable en utilisant notre fonction de changement de numéro dans l'application. Vous acceptez de recevoir des SMS et 
              des appels téléphoniques (de notre part ou de nos fournisseurs tiers) avec des codes pour vous inscrire à nos Services.</p>
              <p>Carnet d'adresses. Vous nous fournissez régulièrement, conformément aux lois applicables, les numéros de téléphone 
              des utilisateurs de PenaltyApp et de vos autres contacts dans votre carnet d'adresses mobile, y compris pour les 
              utilisateurs de nos Services et vos autres contacts.</p>
              <p>Âge. Si vous vivez dans un pays de la région européenne, vous devez avoir au moins 16 ans pour utiliser nos services 
              ou un âge plus élevé requis dans votre pays pour vous inscrire ou utiliser nos services. Si vous vivez dans un autre pays 
              à l'exception de ceux de la région européenne, vous devez avoir au moins 13 ans pour utiliser nos services ou un âge plus 
              élevé requis dans votre pays pour vous inscrire ou utiliser nos services. En plus d'avoir l'âge minimum requis pour utiliser 
              nos Services en vertu de la loi applicable, si vous n'êtes pas assez âgé pour avoir le pouvoir d'accepter nos Conditions 
              dans votre pays, votre parent ou tuteur doit accepter nos Conditions en votre nom.</p>
              <p>Appareils et logiciels. Vous devez fournir certains appareils, logiciels et connexions de données pour utiliser 
              nos Services, que nous ne fournissons pas autrement. Afin d'utiliser nos Services, vous acceptez de télécharger et 
              d'installer manuellement ou automatiquement des mises à jour de nos Services. Vous consentez également à ce 
              que nous vous envoyions des notifications via PenaltyApp de temps à autre, si nécessaire pour vous fournir nos services.</p>
              <p>Frais et taxes. Vous êtes responsable de tous les plans de données de l'opérateur, des frais Internet et des autres 
              frais et taxes associés à votre utilisation de nos services.</p>
              <h3>Politique de confidentialité et données utilisateur</h3>
              <p>PenaltyApp se soucie de votre vie privée. La politique de confidentialité de PenaltyApp décrit nos pratiques en 
              matière d'informations (y compris les messages), y compris les types d'informations que nous recevons et 
              collectons auprès de vous, comment nous utilisons et partageons ces informations, et vos droits en ce qui 
              concerne le traitement des informations vous concernant. La politique de confidentialité définit les bases juridiques 
              de notre traitement des informations personnelles vous concernant, y compris la collecte, l'utilisation, 
              le traitement et le partage de ces informations, ainsi que le transfert et le traitement de ces informations 
              aux États-Unis et dans d'autres pays du monde où nous avons ou utilisons des installations, des fournisseurs de 
              services, des sociétés affiliées ou des partenaires, quel que soit l'endroit où vous utilisez nos services.</p>    
              <h3>Utilisation acceptable de nos services</h3>
              <p>Nos conditions et politiques. Vous devez utiliser nos services conformément à nos conditions et politiques. 
              Si vous enfreignez nos Conditions ou politiques, nous pouvons prendre des mesures concernant votre compte, notamment 
              désactiver ou suspendre votre compte et, si nous le faisons, vous ne devez pas créer un autre compte sans notre 
              autorisation.</p>
              <p>Utilisation légale et acceptable. Vous devez accéder et utiliser nos Services uniquement à des fins légales, 
              autorisées et acceptables. Vous n'utiliserez pas (ou n'aiderez pas d'autres personnes à utiliser) nos Services de 
              manière à: (a) violer, détourner ou enfreindre les droits de PenaltyApp, de nos utilisateurs ou autres, y compris la 
              confidentialité, la publicité, la propriété intellectuelle ou d'autres droits de propriété; (b) sont illégales, obscènes, 
              diffamatoires, menaçantes, intimidantes, harcelantes, haineuses, racistes ou ethniquement offensantes, ou incitent ou 
              encouragent une conduite qui serait illégale ou autrement inappropriée, y compris la promotion de crimes violents; (c) 
              impliquer la publication de mensonges, de fausses déclarations ou de déclarations trompeuses; (d) se faire passer 
              pour quelqu'un; (e) impliquer l'envoi de communications illégales ou non autorisées, telles que la messagerie en masse, 
              la messagerie automatique, la numérotation automatique, etc. ou (f) impliquer toute utilisation non personnelle de 
              nos Services, sauf autorisation contraire de notre part.</p>
              <p>Nuire à PenaltyApp ou à nos utilisateurs. Vous ne devez pas (ou aider les autres à) directement, indirectement, par 
              des moyens automatisés ou autres, accéder, utiliser, copier, adapter, modifier, préparer des œuvres dérivées basées sur, 
              distribuer, concéder sous licence, sous-licencier, transférer, afficher, exécuter ou autrement exploiter nos Services de 
              manière inadmissible ou non autorisée, ou de manière à nous gêner, nuire ou nuire à nos services, systèmes, nos utilisateurs 
              ou autres, y compris que vous ne devez pas directement ou par des moyens automatisés: (a) rétroconcevoir, altérer, 
              modifier , créer des œuvres dérivées de, décompiler ou extraire le code de nos Services; (b) envoyer, stocker ou 
              transmettre des virus ou tout autre code informatique nuisible via ou sur nos Services; (c) obtenir ou tenter d'obtenir 
              un accès non autorisé à nos Services ou systèmes; (d) perturber ou perturber la sûreté, la sécurité ou la performance 
              de nos Services; (e) créer des comptes pour nos Services par des moyens non autorisés ou automatisés; (f) collecter 
              les informations de ou sur nos utilisateurs de toute manière non autorisée ou non autorisée; (g) vendre, revendre, 
              louer ou facturer nos Services d'une manière non autorisée; (h) distribuer ou rendre nos Services disponibles sur 
              un réseau où ils pourraient être utilisés par plusieurs appareils en même temps, sauf autorisation par des outils 
              que nous avons expressément fournis via nos Services; ou (i) créer des logiciels ou des API qui fonctionnent sensiblement 
              de la même manière que nos services et les proposer à des tiers de manière non autorisée.</p>
              <p>Garder votre compte en sécurité. Vous êtes responsable de la sécurité de votre appareil et de votre compte PenaltyApp, 
              et vous devez nous informer rapidement de toute utilisation non autorisée ou violation de la sécurité de votre compte 
              ou de nos services.</p>
              <h3>Services tiers</h3>
              <p>Nos Services peuvent vous permettre d'accéder, d'utiliser ou d'interagir avec des sites Web, des applications, 
              du contenu, d'autres produits et services tiers. Par exemple, vous pouvez choisir d'utiliser des services de sauvegarde 
              de données tiers (tels que iCloud ou Google Drive) qui sont intégrés à nos services ou interagir avec un bouton de 
              partage sur le site Web d'un tiers qui vous permet d'envoyer des informations à vos contacts PenaltyApp . 
              Veuillez noter que ces conditions et la politique de confidentialité s'appliquent uniquement à 
              l'utilisation de nos services. Lorsque vous utilisez des services tiers, leurs propres conditions et 
              politiques de confidentialité régiront votre utilisation de ces services.</p>
              <h3>Licenses</h3>
              <p>Tes droits. PenaltyApp ne revendique pas la propriété des informations que vous soumettez pour votre compte 
              PenaltyApp ou via nos Services. Vous devez avoir les droits nécessaires sur les informations que vous soumettez pour 
              votre compte PenaltyApp ou via nos services et le droit d'accorder les droits et licences dans nos Conditions.</p>
              <p>Droits de PenaltyApp. Nous détenons tous les droits d'auteur, marques de commerce, domaines, logos, habillage 
              commercial, secrets commerciaux, brevets et autres droits de propriété intellectuelle associés à nos services. 
              Vous ne pouvez pas utiliser nos droits d'auteur, marques de commerce, domaines, logos, habillage commercial, brevets 
              ou autres droits de propriété intellectuelle sans votre autorisation expresse et sauf conformément 
              à nos directives de marque. Vous ne pouvez utiliser les marques de commerce de nos sociétés affiliées qu'avec 
              leur autorisation, y compris celles autorisées dans les directives de marque publiées.</p>
              <p>Votre licence pour PenaltyApp. Afin d'exploiter et de fournir nos services, vous accordez à PenaltyApp une 
              licence mondiale, non exclusive, sans redevance, sous-licenciable et transférable pour utiliser, reproduire, 
              distribuer, créer des œuvres dérivées, afficher et exécuter les informations (y compris le contenu ) que vous 
              téléchargez, soumettez, stockez, envoyez ou recevez sur ou via nos Services. Les droits que vous accordez dans 
              cette licence sont dans le but limité d'exploiter et de fournir nos services (tels que nous permettre d'afficher 
              votre photo de profil et votre message d'état, de transmettre vos messages, de stocker vos messages non livrés 
              sur nos serveurs jusqu'à 30 jours nous essayons de les livrer, et autrement comme décrit dans notre politique de 
              confidentialité).</p>
              <p>La licence de PenaltyApp pour vous. Nous vous accordons une licence limitée, révocable, non exclusive, 
              non sous-licenciable et non transférable pour utiliser nos Services, sous réserve et conformément à nos Conditions. 
              Cette licence a pour seul but de vous permettre d'utiliser nos Services de la manière autorisée par nos Conditions. 
              Aucune licence ni aucun droit ne vous sont accordés implicitement ou autrement, à l'exception des licences et des 
              droits qui vous sont expressément accordés.</p>
              <p>Signalement de droits d'auteur, de marques de commerce et d'autres atteintes à la propriété intellectuelle de tiers. 
              Pour signaler des réclamations pour atteinte aux droits d'auteur, marques de commerce ou autres atteintes à la propriété 
              intellectuelle de tiers, veuillez consulter notre Politique de propriété intellectuelle. Nous pouvons prendre des 
              mesures concernant votre compte, notamment en désactivant ou en suspendant votre compte, si vous enfreignez les droits 
              de propriété intellectuelle d'autrui.</p>
              <h3>Avertissements</h3>
              <p>NOUS UTILISERONS DES COMPÉTENCES ET DES SOINS RAISONNABLES POUR VOUS FOURNIR NOS SERVICES ET POUR LE GARDER DANS 
              UN ENVIRONNEMENT SÛR, SÉCURISÉ ET SANS ERREUR, MAIS NOUS NE GARANTISSONS PAS QUE PENALTYAPP FONCTIONNERA TOUJOURS 
              SANS INTERRUPTIONS, RETARDS OU IMPERFECTIONS. VOUS UTILISEZ NOS SERVICES À VOS PROPRES RISQUES ET SOUS RÉSERVE 
              DES AVIS DE NON-RESPONSABILITÉ SUIVANTS. NOUS FOURNISSONS NOS SERVICES SUR UNE BASE "TEL QUEL" SANS AUCUNE GARANTIE 
              EXPRESSE OU IMPLICITE, Y COMPRIS, MAIS SANS S'Y LIMITER, LES GARANTIES DE QUALITÉ MARCHANDE, D'ADÉQUATION À UN USAGE 
              PARTICULIER, DE TITRE, DE NON-CONTREFAÇON ET DE LIBERTÉ DE VIRUS D'ORDINATEUR OU D'AUTRES DOMMAGES NUISIBLES. CODE. 
              NOUS NE GARANTISSONS PAS QUE LES INFORMATIONS FOURNIES PAR NOUS SONT EXACTES, COMPLÈTES OU UTILES, QUE NOS SERVICES 
              SERONT OPÉRATIONNELS, SANS ERREUR, SÉCURISÉS OU SÉCURITAIRES, OU QUE NOS SERVICES FONCTIONNERONT SANS INTERRUPTION, 
              RETARDS OU IMPERFECTIONS. NOUS NE CONTRÔLONS PAS ET NE SOMMES PAS RESPONSABLES DE, CONTRÔLANT COMMENT OU QUAND NOS 
              UTILISATEURS UTILISENT NOS SERVICES OU LES CARACTÉRISTIQUES, SERVICES ET INTERFACES QUE NOS SERVICES FOURNISSENT. 
              NOUS NE SOMMES PAS RESPONSABLES ET NE SONT PAS OBLIGÉS DE CONTRÔLER LES ACTIONS OU LES INFORMATIONS (Y COMPRIS LE CONTENU) 
              DE NOS UTILISATEURS OU D'AUTRES TIERS. VOUS LIBEREZ NOUS, NOS FILIALES, AFFILIES ET NOS ET LEURS ADMINISTRATEURS, 
              DIRIGEANTS, EMPLOYES, PARTENAIRES ET AGENTS (ENSEMBLE, LES "PARTIES PENALTYAPP") DE TOUTE DEMANDE, PLAINTE, 
              CAUSE D'ACTION OU CONTROVERSE (ENSEMBLE, "CLA" ) ET LES DOMMAGES, CONNUS ET INCONNUS, LIÉS À, DÉCOULANT DE OU DE 
              TOUTE MANIÈRE LIÉE À UNE TELLE RÉCLAMATION QUE VOUS AVEZ CONTRE DES TIERS. VOS DROITS EN CE QUI CONCERNE PENALTYAPP 
              NE SONT PAS MODIFIÉS PAR LA CLAUSE DE NON-RESPONSABILITÉ SI LES LOIS DE VOTRE PAYS DE RÉSIDENCE, APPLICABLES EN RAISON 
              DE VOTRE UTILISATION DE NOS SERVICES, NE L'AUTORISENT PAS. SI VOUS ÊTES UN RÉSIDENT DES ÉTATS-UNIS, VOUS RENONCEZ 
              AU CODE CIVIL DE LA CALIFORNIE §1542, QUI DIT: UN COMMUNIQUÉ GÉNÉRAL NE S'ÉTEND PAS AUX RÉCLAMATIONS QUE LE 
              CRÉANCIER NE SAIT PAS OU SUSPECTE D'EXISTER EN SON FAVEUR AU MOMENT DE L'EXÉCUTION DE LA LIBÉRATION, QUI S'IL EST 
              CONNU PAR LUI OU ELLE DOIT AVOIR AFFECTÉ MATÉRIELLEMENT SON RÈGLEMENT AVEC LE DÉBITEUR.</p>
              <h3>Limitation de responsabilité</h3>
              <p>NOTRE RESPONSABILITÉ GLOBALE RELATIVE À, DÉCOULANT DE TOUTE FAÇON OU DE TOUTE MANIÈRE EN LIEN AVEC NOS CONDITIONS, 
              NOUS OU NOS SERVICES SERA LIMITÉE AUX PERTES QUI SONT UNE CONSÉQUENCE RAISONNABLEMENT PRÉVISIBLE D'UNE TELLE 
              INFRACTION (SAUF EN CE QUI CONCERNE LA MORT, DES BLESSURES PERSONNELLES OU MÉPRÉSENTATION FRAUDULEUSE) ET N'EXCÉDERA 
              PAS LE MONTANT QUE VOUS NOUS AVEZ PAYÉ AU COURS DES DOUZE MOIS.</p>
              <p>VOS DROITS À L'ÉGARD DE WHATSAPP NE SONT PAS MODIFIÉS PAR LA LIMITATION PRÉCÉDENTE SI LES LOIS DE VOTRE PAYS 
              DE RÉSIDENCE, APPLICABLES EN RAISON DE VOTRE UTILISATION DE NOS SERVICES, NE L'AUTORISENT PAS.</p>
              <h3>Indemnité</h3>
              <p>Si quelqu'un introduit une réclamation ("Réclamation de tiers") contre nous concernant vos actions, 
              informations ou contenus sur PenaltyApp, vous devrez, dans la mesure permise par la loi, indemniser et tenir 
              les Parties PenaltyApp indemnes de et contre toutes responsabilités, dommages et intérêts , les pertes et les 
              dépenses de toute nature (y compris les frais et frais juridiques raisonnables) liées à, découlant de ou de quelque 
              manière que ce soit en relation avec l'un des éléments suivants: (a) votre accès à ou l'utilisation de nos Services, 
              y compris les informations fournies à cet égard; (b) votre violation de nos Conditions ou de la loi applicable; ou 
              (c) toute fausse déclaration faite par vous. Vous coopérerez aussi pleinement que nous le demanderons pour 
              la défense ou le règlement de toute réclamation de tiers. Vos droits à l'égard de PenaltyApp ne sont pas modifiés 
              par l'indemnisation précédente si les lois de votre pays de résidence, applicables à la suite de votre utilisation 
              de nos services, ne le permettent pas.</p>
              <h3>Résolution des litiges</h3>
              <p>Si vous vivez dans la région européenne. Si vous êtes un consommateur et que vous résidez habituellement sur un 
              territoire de la Région européenne, les lois de votre territoire s'appliqueront à toute Réclamation que vous avez contre 
              nous qui découle ou se rapporte à ces Conditions ou à nos Services, et vous pouvez résoudre votre Réclamation en tout 
              tribunal compétent de votre territoire compétent pour connaître de la Réclamation. Dans tous les autres cas, 
              vous acceptez que la Réclamation doit être résolue par un tribunal irlandais compétent qui a compétence pour la 
              Réclamation et que les lois de l'Irlande régiront les présentes Conditions et toute Réclamation, sans égard aux conflits 
              de dispositions légales.</p>
              <p>Si vous vivez en dehors de la région européenne, des États-Unis ou du Canada. Si vous êtes un consommateur 
              situé en dehors de la région européenne, des États-Unis et du Canada, les lois du pays dans lequel vous résidez 
              s'appliqueront à toute réclamation que vous avez contre nous qui découle ou se rapporte à ces conditions ou à nos services, 
              et vous peut résoudre votre réclamation devant tout tribunal compétent de ce pays qui a compétence pour la réclamation. 
              Dans tous les autres cas, vous acceptez que la Réclamation doit être résolue exclusivement par la Cour du Royaume-Uni 
              que vous soumettez à la juridiction personnelle de l'un ou l'autre de ces tribunaux dans le but de plaider une telle 
              Réclamation, et que les lois du Royaume-Uni régiront ces Conditions et toute réclamation, sans égard aux dispositions 
              relatives aux conflits de lois.</p>
              <h3>Disponibilité et résiliation de nos services</h3>
              <p>Disponibilité de nos services. Nous essayons toujours d'améliorer nos services, ce qui signifie que nous pouvons 
              ajouter ou supprimer nos services, fonctionnalités, fonctionnalités et la prise en charge de certains appareils et 
              plateformes. Nos services peuvent être interrompus, notamment pour des raisons de maintenance, de réparation, 
              de mise à niveau ou de panne de réseau ou d'équipement. Nous pouvons interrompre tout ou partie de nos Services, 
              y compris certaines fonctionnalités et la prise en charge de certains appareils et plateformes, à tout moment 
              après un préavis de 30 jours, si possible. Des événements indépendants de notre volonté peuvent affecter nos 
              services, tels que des événements dans la nature et d'autres événements de force majeure.</p>
              <p>Résiliation. Bien que nous espérons que vous restez un utilisateur PenaltyApp, vous pouvez mettre fin à votre 
              relation avec PenaltyApp à tout moment pour quelque raison que ce soit en supprimant votre compte. 
              Pour obtenir des instructions sur la manière de procéder, veuillez consulter: Supprimer votre compte.</p>
              <p>Nous pouvons également modifier, suspendre ou résilier votre accès ou votre utilisation de nos Services à 
              tout moment pour une conduite suspecte ou illégale, y compris pour fraude, ou si nous pensons raisonnablement 
              que vous violez nos Conditions ou créez un préjudice, un risque ou une éventuelle exposition juridique pour nous, 
              nos utilisateurs ou autres. Les dispositions suivantes survivront à toute résiliation de votre relation avec PenaltyApp: 
              "Licences", "Clauses de non-responsabilité", "Limitation de responsabilité", "Indemnisation", "Résolution des litiges", 
              "Disponibilité et résiliation de nos services" et "Autre", Si vous pensez que la résiliation ou la suspension de 
              votre compte était une erreur, veuillez nous contacter à support@penalty.app.</p>
              <h3>Autre</h3>
              <ul>
                <li>À moins qu'un accord mutuellement signé entre vous et nous ne le stipule autrement, nos conditions constituent 
                l'intégralité de l'accord entre vous et nous concernant PenaltyApp et nos services, et remplacent tous les accords 
                antérieurs.</li>
                <li>Nous nous réservons le droit de désigner à l'avenir que certains de nos Services sont régis par des conditions 
                distinctes (lorsque, le cas échéant, vous pouvez consentir séparément).</li>
                <li>Our Services are not intended for distribution to or use in any country where such distribution or use would 
                violate local law or would subject us to any regulations in another country. We reserve the right to limit our 
                Services in any country.</li>
                <li>Vous vous conformerez à toutes les lois applicables aux États-Unis et en dehors des États-Unis sur le contrôle 
                des exportations et les sanctions commerciales («lois sur l'exportation»). Vous ne pourrez pas, directement ou 
                indirectement, exporter, réexporter, fournir ou autrement transférer nos Services: (a) à toute personne, 
                entité ou pays interdit par les lois sur l'exportation; (b) à toute personne figurant sur les listes de partis 
                restreints du gouvernement américain ou non américain; ou (c) à toute fin interdite par les lois sur 
                l'exportation, y compris les armes nucléaires, chimiques ou biologiques, ou les applications de technologies 
                de missiles sans les autorisations gouvernementales requises. Vous n'utiliserez ni ne téléchargerez nos 
                Services si vous vous trouvez dans un pays restreint, si vous êtes actuellement inscrit sur une liste de 
                parties restreintes aux États-Unis ou hors des États-Unis, ou à toute fin interdite par les lois sur l'exportation, 
                et vous ne déguiserez pas votre emplacement via proxy IP ou d'autres méthodes.</li>
                <li>Toute modification ou renonciation à nos Conditions nécessite notre consentement exprès. 
                Vous avez le droit de mettre fin à votre relation avec PenaltyApp à tout moment en supprimant votre compte.</li>
                <li>Nous pouvons modifier ou mettre à jour ces conditions. Sauf disposition contraire de la loi, 
                nous vous informerons au moins 30 jours à l'avance des modifications apportées à nos Conditions, ce qui vous 
                donnera la possibilité de consulter les Conditions révisées avant de continuer à utiliser nos Services. 
                Nous mettrons également à jour la date de "Dernière modification" en haut de nos Conditions. Les modifications 
                de ces conditions entreront en vigueur au plus tard 30 jours après que nous aurons notifié les modifications prévues. 
                Veuillez noter que nous pourrions ne pas être en mesure de fournir un tel avis pour les modifications de ces 
                Conditions qui sont nécessaires pour faire face aux évolutions techniques de nos Services ou pour des modifications 
                apportées pour des raisons juridiques, qui entreront en vigueur immédiatement. Votre utilisation continue de nos 
                services après la période de préavis des modifications prévues confirme votre acceptation de nos conditions, 
                telles que modifiées. Nous espérons que vous continuerez à utiliser PenaltyApp, mais si vous n'acceptez pas 
                nos Conditions, telles que modifiées, vous devez cesser d'utiliser nos Services en supprimant votre compte.</li>
                <li>Tous nos droits et obligations en vertu de nos Conditions sont librement attribuables par nous à l'une de nos 
                sociétés affiliées ou dans le cadre d'une fusion, d'une acquisition, d'une restructuration ou d'une vente d'actifs, 
                ou par effet de la loi ou autrement, et nous pouvons transférer vos informations à l'un de nos affiliés, 
                entités successeurs ou nouveau propriétaire. Dans le cas d'une telle cession, ces Conditions continueront 
                de régir votre relation avec ce tiers. Nous espérons que vous continuerez à utiliser PenaltyApp, mais si 
                vous n'acceptez pas une telle cession, vous devez cesser d'utiliser nos services en supprimant votre compte.</li>
                <li>Vous ne transférerez aucun de vos droits ou obligations en vertu de nos Conditions à quiconque sans 
                notre consentement écrit préalable.</li>
                <li>Rien dans nos Conditions ne nous empêchera de nous conformer à la loi.</li>
                <li>Sauf dans les cas prévus dans les présentes, nos conditions ne confèrent aucun droit de tiers bénéficiaire.</li>
                <li>Si nous n'appliquons aucune de nos conditions, cela ne sera pas considéré comme une renonciation.</li>
                <li>Si une disposition de ces conditions est jugée illégale, nulle ou inapplicable pour quelque raison que ce soit, 
                cette disposition sera réputée séparable de nos conditions et n'affectera pas la validité et 
                l'applicabilité des dispositions restantes de nos conditions et de la partie restante. de nos 
                Conditions resteront en vigueur et de plein effet.</li>
                <li>Nous nous réservons tous les droits que nous ne vous accordons pas expressément. Dans certaines juridictions, 
                vous pouvez avoir des droits légaux en tant que consommateur, et nos Conditions ne sont pas destinées à l
                imiter ces droits juridiques des consommateurs qui ne peuvent pas être levés par contrat. De plus, dans 
                certaines juridictions, vous pouvez avoir des droits légaux en tant que personne concernée, et nos 
                Conditions ne sont pas destinées à limiter ces droits auxquels le contrat ne peut pas renoncer.</li>
                <li>Nous apprécions toujours vos commentaires ou autres suggestions concernant PenaltyApp et nos services, 
                mais vous comprenez que vous n'avez aucune obligation de fournir des commentaires ou des suggestions et que nous 
                pouvons utiliser vos commentaires ou suggestions sans aucune obligation de vous rémunérer pour eux.</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="decoration-wrapper d-none d-sm-block">
          <div className="decoration right middle-y scale-5" data-jarallax-element="200">
            <img className="bg-primary-2" src="/assets/img/decorations/deco-blob-3.svg" alt="deco-blob-3 decoration" data-inject-svg />
          </div>
          <div className="decoration top left scale-5" data-jarallax-element="50">
            <img className="bg-primary" src="/assets/img/decorations/deco-blob-13.svg" alt="deco-blob-13 decoration" data-inject-svg />
          </div>
        </div>
      </section>
    </>);
}

export default TermsOfUse;
