import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import Home from './containers/Home';
import PrivacyPolicy from './containers/PrivacyPolicy';
import TermsOfUse from './containers/TermsOfUse';
import './App.css';

function App() {
  return(<Router>
  <Switch>
    <Route exact path="/">
      <Home />
    </Route>
    <Route path="/privacy-policy">
      <PrivacyPolicy />
    </Route>
    <Route path="/terms-of-use">
      <TermsOfUse />
    </Route>
  </Switch>
  </Router>);
}

export default App;
